<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
//import axios from 'axios';
import CenterModal from '@/components/modals/center-modal.vue';
import api from '@/helpers/api-rest/api.js';

export default {
    page: {
        title: "Centros",
        meta: [{ name: "description", content: appConfig.description }]
    },
    components: { 
        Layout, 
        PageHeader,
        CenterModal
    },
  data() {
    return {
      tableData: [],
      title: "Centros",
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: true,
      fields: [
        { key: "center_code", sortable: false, label: "Código" },
        { key: "name", sortable: true, label: "Nombre" },
        { key: "address", sortable: false, label: "Dirección" },
        { key: "email", sortable: false, label: "Email" },
        // { key: "center_type_id", sortable: false, label: "Tipo de centro" },
        { key: "status", sortable: false, label: "" },
        { key: "action", tdClass:"custom-table-action",  sortable: false, label: "" }
      ],
      showCenterModal: false,
      editMode: false,
      centerEdit: undefined,
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    }
  },
  methods: {
    loadDataFromApi(){
      const apiEndpoint = `/api/v1/centers`;
      api.get({url:apiEndpoint})
        .then(response => {
          this.tableData = response.data.data;
          console.log(this.tableData);
          this.tableData.forEach(center => {
            center.switchStatus = center.status === true;
          });

        })
        .catch(error => {
          console.error("Error loading data:", error);
        });
    },

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    success(center) {
      console.log(center);
      this.loadDataFromApi();
    },

    addCenter() {
      this.centerEdit = {
        center_code: '',
        name: '',
        director: '',
        address: '',
        city: '',
        province: '',
        cp: '',
        phone: '',
        email: '',
        selectedCenterType: '',
        courses: [],
      };
    },

    editCenter(center) {
        center.role_ids = center?.roles?.map(item => (item?.id));
        this.centerEdit = center;
    },

    toggleStatus(center){
      console.log(center);
      const apiEndpoint = `/api/v1/centers/${center.id}/change-status/?_method=PATCH`;
      api.post({url:apiEndpoint})
        .then(response => {
          console.log(response);
            // center.status = response.data.data.status;
            center.status = (response.data.data.status) ? 1 : 0;
        })
        .catch(error => {
          console.error("Error loading data:", error);
        });
    },

    handleSwitchInput(center) {
      this.toggleStatus(center);
    },

    // downloadFile(filePath){
    //   const baseUrl = process.env.VUE_APP_API_HOST;
    //   const url = `${baseUrl}/download?file=${filePath}`;
    //   window.open(url, '_self');
    // },

  },
  mounted() {
    this.loadDataFromApi();
    // Set the initial number of items
    this.totalRows = this.tableData.length;
  },
}
</script>

<template>
  <Layout>
    <PageHeader :title="title">
      <template v-slot:header-button>
        <button v-b-modal.ModalCenter @click="addCenter()" class="btn btn-success">
          <i class="fa fa-plus"></i> Añadir Centro
        </button>
      </template>
    </PageHeader>
    <CenterModal :show-center-modal="showCenterModal" :center="centerEdit" @success="success" />

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- <h4 class="card-title">Data Table</h4> -->
            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Mostrar&nbsp;
                    <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;registros
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                  <label class="d-inline-flex align-items-center">
                    <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Buscar..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                :items="tableData"
                :fields="fields"
                responsive="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                ref="centerTable"
              >
                <template v-slot:cell(center_type_id)="row">
                  <div v-if="row.item.center_type_id">
                    <b-badge pill :variant="row.item.center_type_id === 1 ? 'info' : 'light'" class="ml-1">
                      {{ row.item.center_type.name }}
                    </b-badge>
                  </div>
                </template>
                <!-- <template v-slot:cell(document_path)="row">
                  <div v-if="row.item.document_path">
                    <a href="javascript:void(0);" class="mr-3 text-secondary" @click.prevent="downloadFile(row.item.document_path)">
                      <i class="mdi mdi-file-download-outline font-size-18"></i>
                    </a>
                  </div>
                </template> -->
                <template v-slot:cell(status)="row">
                  <div>
                    <b-badge
                      pill
                      :variant="row.item.status === 1 ? 'success' : 'danger'"
                      class="ml-1"
                    >
                      {{ row.item.status === 1 ? 'Activo' : 'Inactivo' }}
                    </b-badge>
                  </div>
                </template>
                <template v-slot:cell(action)="row">
                  <div>
                    <a href="javascript:void(0);" class="mr-3 text-secondary" v-b-modal.ModalCenter title="Edit" @click="editCenter(row.item)">
                      <i class="mdi mdi-square-edit-outline font-size-18"></i>
                    </a>
                    <b-form-checkbox
                        v-model="row.item.status"
                        switch class="mb-1"
                        value="1"
                        @change="handleSwitchInput(row.item)"
                    ></b-form-checkbox>
                  </div>
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-right">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>